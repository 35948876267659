import React from "react";

import { Outlet } from "react-router";
import Footer from "./Footer";
import MainNavbar from "./MainNavbar";

const Layout = () => {
  return (
    <div>
      {/* <MainNavbar/> */}
      <Outlet />
      {/* <Footer/> */}
    </div>
  );
};

export default Layout;
