import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import logo from "../../assets/img/logos/fruverLogoIcon.png";

const Landing = () => {
  return (
    <div className="header">
      <Container>
        <img
          src={logo}
          alt="Fruverd Logo"
          style={{
            maxWidth: "200px",
            marginBottom: 40,
            marginTop: 40,
          }}
        />
        <h1 className="coming-soon-header"> ¡Pronto!</h1>
        <Row>
          <Col md={6}>
            <Card className="mb-5 shadow-sm custom-card">
              <Card.Body>
                <Card.Title className="custom-card-title">
                  Oficinas corporativas
                </Card.Title>
                <Card.Text>
                  <strong>Tel:</strong> +506 2226 2726 <br />
                  Barrio Naciones Unidas, Catedral <br />
                  San José, Costa Rica
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4 shadow-sm custom-card">
              <Card.Body>
                <Card.Title className="custom-card-title">
                  Bodega Centro Nacional de Abasto
                </Card.Title>
                <Card.Text>
                  Barreal, Heredia <br />
                  Heredia, Costa Rica <br />
                  <strong>Tel:</strong> +506 2101 1050
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card className="shadow-sm custom-card">
          <Card.Body>
            <Card.Text>
              <strong>Correo electrónico:</strong> info@fruverd.com
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Landing;
