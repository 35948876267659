import React from "react";
import { Routes, Route, Navigate } from "react-router";
import Layout from "../components/Layout";
import Home from "../views/Home";
import { HomePage } from "../views/HomePage";
import Landing from "../views/Landing";

const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Navigate replace to="/home" />} />
          <Route path="home" element={<Landing />} />
          {/* <Route path="/*" element={<Navigate replace to="error" />} /> */}
          {/* <Route path="error" element={<PageNotFound />} />       */}
        </Route>
      </Routes>
    </>
  );
};
export default AppRouter;
