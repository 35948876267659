import React from 'react';
import { BrowserRouter } from 'react-router-dom';
 import './App.scss';
 import AppRouter from "./app/routers/AppRouter";


function App() {
  return (
    <>
    <BrowserRouter>
    <AppRouter />
    </BrowserRouter>
          
    </>
  );
}

export default App;
