import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {Scrollchor} from 'react-scrollchor';
function MainNavbar() {
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  let navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navigateTo = (link) => {
    navigate(link);
  };
  // const [anchorTarget, setAnchorTarget] = useState(null);
  // useEffect(() => {
  //   setAnchorTarget(document.getElementById(itemName));
  // }, [itemName]);
  // const scrollTo = (event) => {
  //   event.preventDefault();
  //   anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
  // };

  return (
    <>
      {/* className={`${navBackground ? "navbar-solid" : "nav-fruverd"}`} */}

      <div className="navbar-webapp">
        <Navbar
          id="navbar-scroll"
          scrolling
          expand="lg"
          fixed="top"
          className="navbar-solid"
        >
          <div className="container pr-4 pl-4">
            <Navbar.Brand>
              <Nav.Link>
                <div
                  className="fruver-logo"
                  onClick={() => navigateTo("/home")}
                >
                  <img
                    src={
                      require("../../assets/img/logos/fruverLogoIcon.png")
                        .default
                    }
                    alt="Fruver Logo"
                  />
                </div>
              </Nav.Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto m-autom mcenterm">
                <Nav.Link onClick={() => navigateTo("/home")} >Inicio</Nav.Link>
                <Nav.Link ><Scrollchor to="#fruverdApp" animate={{offset:-150, duration: 600}}>Fruverd App</Scrollchor></Nav.Link>

                <Nav.Link><Scrollchor to="#about" animate={{offset:-150, duration: 600}}>Sobre Nosotros</Scrollchor></Nav.Link>
                <Nav.Link><Scrollchor to="#contact" animate={{offset:-100, duration: 600}} >Contacto</Scrollchor></Nav.Link>
              </Nav>
              <Nav className="right-nav">
                <Nav.Link>
                  <Button className="btn btn-primary">Tienda</Button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </>
  );
}
export default MainNavbar;
